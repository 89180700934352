import { ImSpinner10 } from "react-icons/im";
import styled from "styled-components";

type SpinnerProps = {
  height: number;
  width: number;
};

export default styled(ImSpinner10)<SpinnerProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  animation: spin 1.25s cubic-bezier(0.42, 0.25, 0.58, 0.75) infinite;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
