import React, { useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../../Redux/Hooks";
import { GetUserThunk } from "../../Thunks/UserContextThunk";

type Props = {
  children?: React.ReactNode;
};

const usePathname = () => {
  const location = useLocation();
  return useMemo(() => location.pathname, [location.pathname]);
};

const UserContext = React.createContext(null);

const AuthWrapper = React.memo(({ children }: Props) => {
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const path = usePathname();
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [userMetadata, setUserMetadata] = useState(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-vizjnu3po6ow4fy4.us.auth0.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${
          user!.sub
        }`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata: metaData } = await metadataResponse.json();

        setUserMetadata(metaData);
      } catch (e) {
        const err = e as Error;
        console.error(err.message);
      }
    };

    if (isAuthenticated) {
      getUserMetadata();
    }
  }, [isAuthenticated, getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        dispatch(GetUserThunk(token));
      });
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  if (
    !isLoading &&
    !isAuthenticated &&
    !path.includes("login") &&
    !redirecting
  ) {
    // TODO: this is some kind of race condition between re-rendering based on state change and location prop change.
    setTimeout(() => setRedirecting(true));
    return <Navigate to={`/login?redirect=${path}`} />;
  }

  return (
    <UserContext.Provider value={userMetadata}>{children}</UserContext.Provider>
  );
});
AuthWrapper.displayName = "AuthWrapper";

export default AuthWrapper;
