import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GetCall } from "../../Api/GetCall";
import { Vote } from "../../Types/UserContextTypes";

function SubmissionViewer({ clubId }: { clubId: number }) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const [submissions, setSubmissions] = useState<any[] | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      getAccessTokenSilently()
        .then(async (token) => {
          const v = await getVote(token);
          const subs = await getSubmissions(v, token);
          setSubmissions(subs);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    } else {
      console.log("Not authed");
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const getSubmissions = async (v: Vote, token: string) => {
    const URL = `/api/submissions/${clubId}/${v.id}/manage`;
    const submissions = await GetCall(URL, token);
    return submissions;
  };

  const getVote = async (token: string) => {
    const URL = `/api/votes/${clubId}/latest`;
    const v = await GetCall(URL, token);
    return v;
  };

  if (loading || !submissions || !submissions.length) return null;

  return (
    <>
      <h2>Current submissions by member:</h2>
      <table>
        <thead>
          <tr>
            <th>Member</th>
            <th>Game</th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((sub, i) => (
            <tr key={i}>
              <td>{sub.member.user.name}&nbsp;</td>
              <td><b>{sub.name}</b></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default SubmissionViewer;
