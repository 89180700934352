import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemType, VoteOptionState } from "../Types/VotingTypes";

const initialState: VoteOptionState = {
  items: null,
  loading: false,
  error: null,
};

const reorder = (items: ItemType[]): ItemType[] => {
  const [visible, hidden] = items.reduce((acc: ItemType[][], item: ItemType) => {
    if (item.ranked) {
      acc[0].push(item);
    } else {
      acc[1].push(item);
    }
    return acc;
  }, [[], []]);

  return [...visible, ...hidden];
};

export const voteOptionsSlice = createSlice({
  name: "voteOptions",
  initialState,
  reducers: {
    startFetch: (state: VoteOptionState) => {
      state.loading = true;
      state.items = null;
    },
    fetchSuccess: (state: VoteOptionState, action: PayloadAction<ItemType[]>) => {
      state.loading = false;
      state.items = action.payload;
    },
    fetchFailure: (state: VoteOptionState, action: PayloadAction<Error>) => {
      state.loading = false;
      state.items = null;
      state.error = action.payload;
    },
    updateOrder: (state: VoteOptionState, action: PayloadAction<ItemType[]>) => {
      const hidden = state.items?.filter(({ ranked }: ItemType) => !ranked) || [];
      const items = action.payload;
      state.items = [...items, ...hidden];
      state.loading = false;
    },
    stageItem: (state: VoteOptionState, action: PayloadAction<ItemType>) => {
      if (state.items) {
        const index = state.items.findIndex((i: ItemType) => i.id === action.payload.id);
        if (index > -1 && state.items && state.items.length) {
          state.items[index].ranked = true;
          state.items = reorder(state.items);
        }

        state.loading = false;
      }
    },
    unstageItem: (state: VoteOptionState, action: PayloadAction<ItemType>) => {
      if (state.items) {
        const index = state.items?.findIndex((i: ItemType) => i.id === action.payload.id);
        if (index > -1 && state.items && state.items.length) {
          state.items[index].ranked = false;
          state.items = reorder(state.items);
        }

        state.loading = false;
      }
    }
  }
});

export const { startFetch, fetchSuccess, fetchFailure, updateOrder, stageItem, unstageItem } = voteOptionsSlice.actions;

export default voteOptionsSlice.reducer;
