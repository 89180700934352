import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import buildHeaders from "../Api/AuthUtil";
import { GetCall, PostCall } from "../Api/GetCall";
import SubmitButton from "../Components/InputComponents/SubmitButton";
import Spinner from "../Components/Spinner/Spinner";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks";
import { GetUserThunk } from "../Thunks/UserContextThunk";

const JoinedClub = styled.div`
  margin: 8px;
  width: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ClubSelection = () => {
  const { clubs: memberClubs } = useAppSelector((state) => state.context);
  const navigate = useNavigate();
  const clubs = memberClubs.map((m) => m.club);
  const [invites, setInvites] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newClubName, setNewClubName] = useState<string>("");
  const [creatingClub, setCreatingClub] = useState<boolean>(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const getInvites = async (accessToken: string) => {
    try {
      setLoading(true);
      const invites = await GetCall("/api/invites/", accessToken);
      setInvites(invites);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setAccessToken(token);
      dispatch(GetUserThunk(token));
      getInvites(token);
    });
  }, [user, isAuthenticated, getAccessTokenSilently]);

  if (loading) {
    return <Spinner width={50} height={50} />;
  }

  const acceptClubInvite = async (invite: any) => {
    if (!accessToken) {
      return;
    }
    setLoading(true);
    await fetch(
      `/api/invites/${invite.club.id}/mine`,
      buildHeaders(accessToken!, "POST")
    );
    setLoading(false);
    dispatch(GetUserThunk(accessToken));
    await getInvites(accessToken);
  };

  const createClub = async () => {
    if (!accessToken) return;
    setCreatingClub(true);
    await PostCall(
      "/api/clubs",
      accessToken,
      JSON.stringify({ name: newClubName })
    );
    setCreatingClub(false);
    dispatch(GetUserThunk(accessToken));
  };

  return (
    <Wrapper>
      {!clubs.length && "You don't belong to any clubs yet."}
      {!!clubs && <h2>Your Clubs</h2>}
      {!!clubs.length &&
        clubs.map((c, i) => (
          <JoinedClub key={i}>
            <SubmitButton
              text={c.name}
              onClick={() => navigate(`/club/${c.id}`)}
            />
          </JoinedClub>
        ))}
      <>
        {invites && !!invites.length && <h2>Pending invites</h2>}
        {invites &&
          !!invites.length &&
          invites.map((invite, i) => (
            <div key={i}>
              <b>{invite.club.name}</b>&nbsp;
              <a href="#" onClick={() => acceptClubInvite(invite)}>
                Accept
              </a>
            </div>
          ))}
      </>
      <h2>Create a new club</h2>
      <label>
        Club Name:&nbsp;
        <input
          type="text"
          onChange={(e) => setNewClubName(e.target.value)}
          value={newClubName}
          maxLength={24}
        />
      </label>
      <br />
      <SubmitButton
        disabled={creatingClub}
        text="Create"
        onClick={createClub}
      />
    </Wrapper>
  );
};

export default ClubSelection;
