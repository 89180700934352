import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { DeleteCall, GetCall, PostCall } from "../Api/GetCall";
import MemberManagerTable from "../Components/MemberManager/MemberManagerTable";
import Spinner from "../Components/Spinner/Spinner";
import MarkdownEditor from "@uiw/react-markdown-editor";
import SubmitButton from "../Components/InputComponents/SubmitButton";
import { updateMotd } from "../Api/Manage";
import { Club, Vote } from "../Types/UserContextTypes";
import VoteManager from "../Components/VoteManager/VoteManager";
import { useAuth0 } from "@auth0/auth0-react";
import SubmissionViewer from "../Components/SubmissionViewer/SubmissionViewer";
import VoteTurnout from "../Components/VoteTurnout/VoteTurnout";

export type Member = {
  role: string;
  id: string;
  user: {
    name: string;
    email: string;
  };
};

const MarkdownEditorStyled = styled(MarkdownEditor)`
  width: 70%;
  min-height: 500px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
`;

const ManageClub = () => {
  const [club, setClub] = useState<Club | null>(null);
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [creatingVote, setCreatingVote] = useState<boolean>(false);
  const [motd, setMotd] = useState<string>("");
  const [motdDirty, setMotdDirty] = useState<boolean>(false);
  const [vote, setVote] = useState<Vote | null>(null);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { clubId } = useParams();
  const onFinish = (c: Club) => {
    setLoading(false);
    setClub(c);
    setMotd(c.motd);
  };

  useEffect(() => {
    getAccessTokenSilently().then(setAccessToken);
  }, [user, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (!accessToken) return;
    setLoading(true);
    GetCall(`/api/clubs/${clubId}`, accessToken.toString())
      .then(onFinish)
      .then(() => getActiveVote(accessToken))
      .catch((ex) => {
        console.error(ex);
        setLoading(false);
      });
  }, [accessToken, clubId]);

  useEffect(() => {
    getMembers();
  }, [accessToken, clubId]);

  const getActiveVote = async (token: string) => {
    try {
      setLoading(true);
      const URL = `/api/votes/${clubId}/latest`;
      const vote = await GetCall(URL, token);
      setVote(vote);
      setLoading(false);
    } catch (ex) {
      console.error(ex);
      setVote(null);
      setLoading(false);
    }
  };

  const getMembers = async () => {
    if (!accessToken) return;
    try {
      setLoading(true);
      const URL = `/api/members/${clubId}/all`;
      const members = await GetCall(URL, accessToken.toString());
      setMembers(members);
    } catch (ex) {
      console.error(ex);
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner width={50} height={50} />;
  }

  if (!club) {
    return <>Couldn&apos;t find this club</>;
  }

  const handleDelete = async (m: Member) => {
    const confirmed = confirm(
      `Do you want to remove ${m.user.name} from the group?`
    );
    if (confirmed) {
      setLoading(true);
      await DeleteCall(`/api/members/${clubId}/${m.id}`, accessToken!);
      await getMembers();
      setLoading(false);
    }
  };

  const saveMotd = async () => {
    if (clubId && accessToken) {
      await updateMotd(motd, clubId, accessToken);
      setMotdDirty(false);
    }
  };

  const updateMotdState = (text: string) => {
    setMotd(text);
    setMotdDirty(true);
  };

  const createVote = async () => {
    if (!accessToken) {
      return;
    }
    setCreatingVote(true);
    try {
      await PostCall(`/api/votes/${clubId}`, accessToken);
    } catch (ex) {
      console.error(ex);
    }
    setCreatingVote(false);
  };

  return (
    <Wrapper>
      <h2>Manage {club.name}</h2>
      <h3>MOTD</h3>
      <MarkdownEditorStyled
        value={motd}
        onChange={updateMotdState}
        toolbarsMode={["preview"]}
        toolbars={[
          "bold",
          "italic",
          "header",
          "strike",
          "underline",
          "olist",
          "ulist",
          "link",
          "todo",
          "image",
        ]}
        visibleEditor
        visible
      />
      <br />
      <SubmitButton disabled={!motdDirty} text="Save MOTD" onClick={saveMotd} />
      <br />
      <h3>Votes</h3>
      {!!clubId && !creatingVote && vote && <VoteManager vote={vote} clubId={clubId} />}
      {creatingVote && <Spinner width={32} height={32} />}
      <br />
      <SubmitButton
        disabled={creatingVote}
        text="Create new voting round"
        onClick={createVote}
      />
      {vote && accessToken && <VoteTurnout vote={vote} accessToken={accessToken} />}
      {clubId && <SubmissionViewer clubId={+clubId} />}
      <hr />
      <h3>Members</h3>
      {members && !!members.length && (
        <MemberManagerTable
          members={members}
          handleDelete={(m) => handleDelete(m)}
        />
      )}
    </Wrapper>
  );
};

export default ManageClub;
