import React, { KeyboardEventHandler, useEffect, useState } from "react";
import Spinner from "../Spinner/Spinner";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { searchGamesThunk } from "../../Thunks/SearchThunks";
import { DebounceInput } from "react-debounce-input";
import { proto } from "ts-igdb-client/proto/compiled";
import { styled } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchContainer = styled("span")`
  position: relative;
`;

const SearchDropdown = styled("div")`
  position: absolute;
  top: 28px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 10;
`;

const SearchResultItem = styled("div")(({ theme }) => ({
  padding: "8px",
  borderColor: "black",
  borderStyle: "solid",
  borderWidth: "0 1px 1px 1px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.primary.light,
  "&.selected": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:first-child": {
    borderWidth: "1px 1px 1px 1px !important",
  },
  ":hover, :active": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Artwork = styled("img")`
  border-radius: 5px;
  margin-right: 8px;
  height: 64px;
  width: 64px;
`;

const LoadingSpinner = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "28px",
}));

const SearchInput = styled(DebounceInput)`
  padding: 8px;
  min-width: 400px;
  width: 100%;
`;

export interface Props {
  selectGame: (game: proto.IGame) => void;
}

function GameSearchBox({ selectGame }: Props) {
  const [activeResult, updateActiveResult] = useState<number>(0);
  const [searchText, updateSearchText] = useState<string>("");
  const { results, loading, error } = useAppSelector((state) => state.search);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        setAccessToken(token);
      });
    } else {
      console.log("Not authed");
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const handleNav: KeyboardEventHandler = (e) => {
    const key = e.code;
    if (key === "ArrowUp" || (key === "Tab" && !!e.shiftKey)) {
      e.preventDefault();
      updateActiveResult(Math.max(activeResult - 1, 0));
    } else if (key === "ArrowDown" || key === "Tab") {
      e.preventDefault();
      updateActiveResult(Math.min(activeResult + 1, results.length - 1));
    } else if (key === "Enter" && !loading) {
      e.preventDefault();
      handleSelection(activeResult);
      // TODO: select this item, and bubble up the event to replace the input box with a pill [(Pic) Game Name X]
    }
  };

  const handleSelection = (i: number) => {
    const selection = results[i];
    selectGame(selection);
    updateSearchText("");
  };

  const handleSearchInputChange = (value: string) => {
    updateSearchText(value);
    updateActiveResult(0);
    dispatch(searchGamesThunk(value, accessToken!));
  };

  const getImage = (url?: string | null) => {
    if (!url) {
      return <BrokenImageIcon sx={{ width: "64px", height: "64px" }} />;
    }

    return <Artwork src={url} />;
  };

  return (
    <Container>
      {!!error && <>{error.message}</>}
      <label>
        Search for a game:&nbsp;
        <br />
        <SearchContainer>
          <SearchInput
            minLength={3}
            type="text"
            placeholder="Search..."
            debounceTimeout={500}
            onChange={(e: any) => handleSearchInputChange(e.target.value)}
            onKeyDown={handleNav}
          />
          <SearchDropdown>
            {!!searchText && (
              <>
                {loading && (
                  <LoadingSpinner>
                    <Spinner width={20} height={20} />
                  </LoadingSpinner>
                )}
                {!!results.length &&
                  results.map((r, i) => (
                    <SearchResultItem
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelection(i);
                      }}
                      className={i === activeResult ? "selected" : ""}
                    >
                      {getImage(r.cover?.url)}
                      <div style={{ textAlign: "center", padding: "8px" }}>
                        {r.name}
                      </div>
                      <div>
                        (
                        {r.first_release_date
                          ? new Date(r.first_release_date * 1000).getFullYear()
                          : "?"}
                        )
                      </div>
                    </SearchResultItem>
                  ))}
              </>
            )}
          </SearchDropdown>
        </SearchContainer>
      </label>
    </Container>
  );
}

export default GameSearchBox;
