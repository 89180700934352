import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GamePill from "../Components/GamePill/GamePill";
import GameSearchBox from "../Components/GameSearchBox/GameSearchBox";
import SubmitButton from "../Components/InputComponents/SubmitButton";
import { proto } from "ts-igdb-client/proto/compiled";
import { useParams } from "react-router-dom";
import buildHeaders from "../Api/AuthUtil";
import Spinner from "../Components/Spinner/Spinner";
import { GetCall } from "../Api/GetCall";
import { Vote } from "../Types/UserContextTypes";
import VoteResults, {
  buildArtworkUrl,
  buildGameLinks,
  Container,
  Wrapper,
} from "../Components/VoteResults/VoteResults";
import { Divider } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const ButtonContainer = styled.div`
  display: flex;
  width: 300px;
  justify-content: end;
  margin-top: 16px;
`;

const Separator = styled(Divider)`
  width: 100%;
`;

function SubmitPage() {
  const [loadingVote, setLoadingVote] = useState<boolean>(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [game, setGame] = useState<proto.IGame | null>(null);
  const [winningGame, setWinningGame] = useState<proto.IGame | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [vote, setVote] = useState<Vote | null>(null);
  const { clubId } = useParams();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        setAccessToken(token);
      });
    } else {
      console.log("Not authed");
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const submitSubmission = async () => {
    if (!game) return;

    setSubmitting(true);
    if (!accessToken || !vote) return;
    const URL = `/api/submissions/${clubId}/${vote.id}/create/${game.id}`;
    await fetch(URL, buildHeaders(accessToken.toString(), "POST"))
      .then((r) => r.json())
      .catch((ex) => console.error(ex));

    setSuccess(true);
    setSubmitting(false);
  };

  const getVote = async (accessToken: string) => {
    try {
      setLoadingVote(true);
      const URL = `/api/votes/${clubId}/latest`;
      const v = await GetCall(URL, accessToken.toString());
      setVote(v);
      setLoadingVote(false);
    } catch (ex) {
      console.error(ex);
      setVote(null);
      setLoadingVote(false);
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    getVote(accessToken);
  }, [accessToken, clubId]);

  useEffect(() => {
    if (!accessToken || !vote) return;
    setLoading(true);
    const URL = `/api/submissions/${clubId}/${vote.id}/`;
    GetCall(URL, accessToken)
      .then((game) => {
        setGame(game);
        setLoading(false);
      })
      .catch((ex) => {
        console.error(ex);
        setLoading(false);
      });
  }, [accessToken, clubId, vote]);

  useEffect(() => {
    if (vote && vote.chosen_game_id && accessToken) {
      getGameFromId(vote.chosen_game_id, accessToken);
    }
  }, [accessToken, clubId, vote]);

  const getGameFromId = async (gameId: number, accessToken: string) => {
    const game = await GetCall(`/api/games/${gameId}`, accessToken);
    setWinningGame(game);
  };

  const links = buildGameLinks(game);
  const artworkUrl = game ? buildArtworkUrl(game) : undefined;

  if (loading || loadingVote) {
    return (
      <Container artworkUrl={artworkUrl}>
        <Wrapper>
          <Spinner width={50} height={50} />
        </Wrapper>
      </Container>
    );
  }

  if (!vote?.open && vote?.chosen_game_id) {
    return <VoteResults game={winningGame} voteId={vote.id} />;
  }

  if (!vote?.submissions_open) {
    return <h2>Submissions are closed for this round.</h2>;
  }

  return (
    <Container artworkUrl={artworkUrl}>
      <Wrapper>
        <h1>Submit a game!</h1>
        {success && <h2>Successfully submitted game!</h2>}
        {!game && <GameSearchBox selectGame={setGame} />}
        {!!game && (
          <>
            <GamePill
              text={game.name?.toString() || ""}
              handleDelete={() => setGame(null)}
              iconUrl={game.cover ? `https:${game.cover.url}` : undefined}
            />
            <Separator />
            {links &&
              !!links.length &&
              links.map(([url, name], i) => (
                <a href={url} target={`_new${i}`} key={i}>
                  {name}
                </a>
              ))}
            <a href={game.url?.toString()} target="_new">
              IGDB page
            </a>
            {game.platforms && (
              <>
                <Separator />
                <div>Platforms:</div>
                {game.platforms.map((p, i) => (
                  <span key={i}>{p.name}&nbsp;</span>
                ))}
              </>
            )}
            <Separator />
          </>
        )}
        <ButtonContainer>
          <SubmitButton
            disabled={!game}
            loading={submitting}
            text="Submit"
            onClick={() => submitSubmission()}
          />
        </ButtonContainer>
      </Wrapper>
    </Container>
  );
}

export default SubmitPage;
