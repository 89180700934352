import { submitItems } from "../Api/UpdateList";
import { startSubmit, submitFailure, submitSuccess } from "../Reducers/Voting";
import { TypedThunk } from "../Redux/Hooks";
import { Ranked } from "../Types/VotingTypes";

const submitVotes = (items: Ranked[], voteId: number, token: string): TypedThunk => async (dispatch) => {
  dispatch(startSubmit());
  try {
    await submitItems(items, voteId, token);
    dispatch(submitSuccess());
  } catch (exception) {
    console.error(exception);
    dispatch(submitFailure(exception as Error));
  }
};

export {
  submitVotes,
};
