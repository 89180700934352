import { Button } from "@mui/material";
import React from "react";
import Spinner from "../Spinner/Spinner";

export enum ButtonType {
  PRIMARY,
  SECONDARY,
}

export interface SubmitButtonProps {
  text: string;
  onClick: () => void;
  type?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
}

function SubmitButton({
  type = ButtonType.PRIMARY,
  onClick,
  text,
  disabled = false,
  loading = false,
}: SubmitButtonProps) {
  if (loading) {
    return (
      <Button disabled>
        {text}&nbsp;
        <Spinner height={16} width={16} />
      </Button>
    );
  }

  if (disabled) {
    return <Button disabled>{text}</Button>;
  }

  const variant =
    type === ButtonType.PRIMARY ? "contained" : "outlined";
  return <Button variant={variant} onClick={() => onClick()}>{text}</Button>;
}

export default SubmitButton;
