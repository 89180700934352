import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VotingState } from "../Types/VotingTypes";

const initialState: VotingState = {
  submitting: false,
  submitted: false,
  error: null,
};

export const votingSlice = createSlice({
  name: "voting",
  initialState,
  reducers: {
    startSubmit: (state: VotingState) => {
      state.submitting = true;
      state.submitted = false;
    },
    submitSuccess: (state: VotingState) => {
      state.submitting = false;
      state.submitted = true;
    },
    submitFailure: (state: VotingState, action: PayloadAction<Error>) => {
      state.submitting = false;
      state.submitted = false;
      state.error = action.payload;
    },
  },
});

export const { startSubmit, submitFailure, submitSuccess } = votingSlice.actions;

export default votingSlice.reducer;
