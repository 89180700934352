import React from "react";
import { TiDelete } from "react-icons/ti";
import styled from "styled-components";
const Icon = styled(TiDelete)`
  height: 24px;
  width: 24px;
  align-self: center;
  margin-left: 8px;
  cursor: pointer;
  margin-right: -6px;
`;

type Props = {
  handleDelete: () => void;
};

const DeleteIcon = ({ handleDelete }: Props) => {
  return <Icon onClick={() => handleDelete()} />;
};

export default DeleteIcon;
