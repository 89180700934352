import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: "InstrumentationKey=0bd77fcb-41ed-44e4-9c0b-c20bffa7e7f3;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/",
    extensions: [reactPlugin],
    enableAutoRouteTracking: true
  }
});
appInsights.loadAppInsights();

export {
  reactPlugin,
  appInsights
};
