import { styled } from "@mui/material";
import React from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { ItemType } from "../../Types/VotingTypes";
import ListItem from "./ListItem";

const reorderItems = (
  list: ItemType[],
  startIndex: number,
  endIndex: number
): ItemType[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const StyledDroppable = styled("div")<{ count: number }>(({ theme }) => ({
  width: "300px",
  marginTop: "10px",
  borderRadius: "10px",
  padding: "8px 8px 8px 8px",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.getContrastText(theme.palette.primary.light),
  border: `1px solid ${theme.palette.getContrastText(theme.palette.primary.light)}`,
  minWidth: "300px",
}));

export interface ListProps {
  items: ItemType[] | null;
  loading: boolean;
  count: number;
  reorder: (items: ItemType[]) => void;
  unstage: (item: ItemType) => void;
}

function List(props: ListProps) {
  const { items, loading, reorder, unstage } = props;
  if (items === null || loading === true) return <>Loading</>;

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reordered = reorderItems(
      items,
      result.source.index,
      result.destination.index
    );

    reorder(reordered);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <StyledDroppable
            count={props.count}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <ListItem
                item={item}
                key={item.id as number}
                index={index}
                remove={() => unstage(item)}
              />
            ))}
            {provided.placeholder}
          </StyledDroppable>
        )}
      </Droppable>
    </DragDropContext>
  );
}
export default List;
