import { API_URL } from "../Config";
import buildHeaders from "./AuthUtil";

export const gameSearch = async (query: string, token: string) => {
  // TODO: put this in a single place
  const searchParams = new URLSearchParams({
    query,
  });
  const URL = `${API_URL}/api/games/search?${searchParams}`;

  const response = await fetch(URL, buildHeaders(token));

  return response.json();
};
