import React from "react";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MemberClub } from "../../Types/UserContextTypes";
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import TuneIcon from "@mui/icons-material/Tune";

export type ClubListItemProps = {
  memberClub: MemberClub;
};

function ClubListitem(props: ClubListItemProps) {
  const { memberClub } = props;
  const { club } = memberClub;
  const vote = club.votes[0];
  const navigate = useNavigate();

  const isAdmin = () => {
    return memberClub.role !== "member";
  };

  return (
    <>
      {!!club && (
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`${club.id}`}
          >
            <Typography>{club.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate(`/club/${club.id}/`)}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
              {!!vote && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(`/club/${club.id}/vote`)}>
                      <ListItemIcon>
                        <HowToVoteIcon />
                      </ListItemIcon>
                      <ListItemText primary="Vote" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(`/club/${club.id}/submit`)}>
                      <ListItemIcon>
                        <VideogameAssetIcon />
                      </ListItemIcon>
                      <ListItemText primary="Submit" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/suggestions")}>
                      <ListItemIcon>
                        <VideogameAssetIcon />
                      </ListItemIcon>
                      <ListItemText primary="Get Suggestions" />
                    </ListItemButton>
                  </ListItem>
                </>
              )}
              {isAdmin() && (
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate(`/club/${club.id}/manage`)}>
                    <ListItemIcon>
                      <TuneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage" />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

export default ClubListitem;
