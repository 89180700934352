function TryGetItem<T>(key: string) {
  const result = localStorage.getItem(key);
  if (result) {
    return JSON.parse(result) as T;
  }
};

function SetItem<T>(key: string, value: T) {
  return localStorage.setItem(key, JSON.stringify(value));
};

export {
  TryGetItem,
  SetItem,
};
