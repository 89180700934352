import { combineReducers, configureStore } from "@reduxjs/toolkit";
import user from "../Reducers/UserReducer";
import voteOptions from "../Reducers/VoteOptions";
import voting from "../Reducers/Voting";
import search from "../Reducers/SearchReducer";
import context from "../Reducers/UserContextReducer";

const rootReducer = combineReducers({ user, voteOptions, voting, search, context });

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
