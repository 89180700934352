import React, { useEffect, useState } from "react";
import { proto } from "ts-igdb-client/proto/compiled";
import { useAuth0 } from "@auth0/auth0-react";
import { PostCall } from "../Api/GetCall";
import SubmitButton from "../Components/InputComponents/SubmitButton";
import GameSearchBox from "../Components/GameSearchBox/GameSearchBox";
import GamePill from "../Components/GamePill/GamePill";

type GameSuggestion = {
  name: string;
  summary: string;
  cover: string;
  platforms: string[]; // Assuming platforms and genres are arrays of strings
  genres: string[];
  rating: number;
  release_date: string;
  game_link: string;
};

type SuggestionsResponse = {
  suggestions: GameSuggestion[];
};

const topStyles: React.CSSProperties = {
  maxWidth: "400px"
};

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

function SuggestionsPage() {
  const [selectedGames, setSelectedGames] = useState<(proto.IGame | null)[]>([]);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<SuggestionsResponse | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        setAccessToken(token);
      });
    } else {
      console.log("Not authed");
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const selectGame = (game: proto.IGame | null) => {
    if (game && selectedGames.length < 10) {
      setSelectedGames((prevGames) => [...prevGames, game]);
    }
  };

  const removeGame = (index: number) => {
    setSelectedGames((prevGames) => prevGames.filter((_, i) => i !== index));
  };

  const submitGames = async () => {
    setIsLoading(true);
    const games = selectedGames.map((game) => game?.id);
    const response = await PostCall("/api/suggestions", accessToken!, JSON.stringify({ pastGames: games })) as SuggestionsResponse;
    setResult(response);
    setIsLoading(false);
  };

  return (
    <div style={containerStyle}>
      <div style={topStyles}>
        <h2>Select up to 10 games you have played and enjoyed</h2>
        {selectedGames.length < 10 && <GameSearchBox selectGame={selectGame} />}
        {selectedGames.map((game, index) => game &&
              <GamePill
                key={index}
                text={game.name?.toString() || ""}
                handleDelete={() => removeGame(index)}
                iconUrl={game.cover ? `https:${game.cover.url}` : undefined}
              />
        )}
        <SubmitButton
          disabled={selectedGames.length === 0}
          loading={isLoading}
          text="Submit"
          onClick={() => submitGames()}
        />
      </div>
      {result &&
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {result.suggestions.map((suggestion, index) => (
          <div key={index} style={{ maxWidth: "300px", margin: "10px", border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }}>
            <h2>{suggestion.name}</h2>
            <img src={suggestion.cover} alt={suggestion.name} style={{ width: "100%" }} />
            <p>{suggestion.summary}</p>
            <p>Rating: {suggestion.rating}</p>
            <p>Release Date: {suggestion.release_date}</p>
            <a href={suggestion.game_link} target="_blank" rel="noreferrer">More Info</a>
          </div>
        ))}
      </div>
      }
    </div>
  );
}

export default SuggestionsPage;
