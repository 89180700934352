import { Ranked } from "../Types/VotingTypes";
import buildHeaders from "./AuthUtil";

const submitItems = async (items: Ranked[], voteId: number, token: string) => {
  const URL = `/api/ballots/${voteId}`;
  return await fetch(URL, buildHeaders(token, "POST", JSON.stringify({ ballots: items })));
};

export {
  submitItems
};
