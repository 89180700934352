import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SubmitButton from "../Components/InputComponents/SubmitButton";
import { useAppDispatch } from "../Redux/Hooks";
import { logout } from "../Reducers/UserReducer";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginPage = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout: auth0Logout,
  } = useAuth0();
  const query = useQuery();
  const redirect = query.get("redirect");
  const dispatch = useAppDispatch();

  const doLogout = () => {
    auth0Logout({ returnTo: window.location.origin });
    dispatch(logout());
  };

  if (isAuthenticated) {
    return <Navigate to={`${redirect || "/"}`} />;
  }

  return (
    <div>
      <h1>Welcome to Video Game Scholars</h1>
      <h3>Please click log in to either make an account or log in.</h3>
      {!isAuthenticated && (
        <SubmitButton text="Log in" onClick={() => loginWithRedirect()} />
      )}
      <br />
      {isAuthenticated && (
        <SubmitButton text="Log out" onClick={() => doLogout()} />
      )}
    </div>
  );
};

export default LoginPage;
