import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "styled-components";
import { logout } from "../../Reducers/UserReducer";
import { useAppDispatch } from "../../Redux/Hooks";

interface AvatarProps {
  avatarUri: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 8px 16px 8px;
`;

const Avatar = styled.div<AvatarProps>`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-image: Url(${(props) => props.avatarUri});
  background-position: center;
  background-size: cover;
`;

const TextWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

function HeaderProfile() {
  const { user, isAuthenticated, logout: auth0Logout } = useAuth0();

  const dispatch = useAppDispatch();

  if (!isAuthenticated || !user) return null;

  const doLogout = () => {
    auth0Logout({ returnTo: window.location.origin + "/" });
    dispatch(logout());
  };

  return (
    <Container>
      <Avatar avatarUri={user.picture || ""} />
      <TextWrapper>
        <div>
          Welcome,&nbsp;
          {user.name}!
        </div>
        <a href="#" onClick={doLogout}>
          Logout
        </a>
      </TextWrapper>
    </Container>
  );
}

export default HeaderProfile;
