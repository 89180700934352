import { styled } from "@mui/material";
import React from "react";
import DeleteIcon from "../DeleteIcon";

export interface Props {
  handleDelete?: () => void;
  onClick?: () => void;
  iconUrl?: string;
  text: string;
  innerRef?: any;
}

const Pill = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: "10px",
  padding: "16px",
  margin: "0 0 8px 0",
  border: `1px solid ${theme.palette.getContrastText(theme.palette.primary.main)}`,
  backgroundColor: theme.palette.primary.main,
  minHeight: "25px",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center",
}));

const Artwork = styled("img")`
  border-radius: 5px;
  margin-right: 8px;
  height: 64px;
  width: 64px;
`;

const GamePill = (props: Props) => {
  return (
    <Pill {...props} ref={props.innerRef}>
      <div>{props.iconUrl && <Artwork src={props.iconUrl} />}</div>
      <div>{props.text}</div>
      <div>
        {!!props.handleDelete && (
          <DeleteIcon handleDelete={() => props.handleDelete!()} />
        )}
      </div>
    </Pill>
  );
};

export default GamePill;
