const buildHeaders = (token: string, method: string = "GET", body?: string) => {
  const bearerToken = `Bearer ${token}`;
  return {
    method,
    headers: {
      Authorization: bearerToken,
      "Content-Type": "application/json"
    },
    ...(body ? { body } : {})
  };
};

export {
  buildHeaders as default
};
