import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { ItemType } from "../../Types/VotingTypes";
import GamePill from "../GamePill/GamePill";

export interface ListItemProps {
  item: ItemType;
  index: number;
  key: number;
  remove: () => void;
}

function ListItem({ item, index, remove }: ListItemProps) {
  return (
    <Draggable draggableId={`${item.id}`} index={index}>
      {(provided) => (
        <GamePill
          innerRef={provided.innerRef}
          text={item.name as string}
          iconUrl={item.cover ? `https:${item.cover.url}` : undefined}
          handleDelete={remove}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        />
      )}
    </Draggable>
  );
}
export default ListItem;
