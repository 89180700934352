import React from "react";
import styled from "styled-components";
import DeleteIcon from "../DeleteIcon";

export interface Props {
  handleDelete: () => void;
  name: string;
  email: string;
  role: string;
}

const MemberWrapper = styled.tr`
  td: {
    display: flex;
  }
`;

const MemberManagerRow = ({ name, email, role, handleDelete }: Props) => {
  return (
    <MemberWrapper>
      <td>{name}&nbsp;</td>
      <td>{email}&nbsp;</td>
      <td>{role}&nbsp;</td>
      <td>
        <DeleteIcon handleDelete={() => handleDelete()} />
      </td>
    </MemberWrapper>
  );
};

export default MemberManagerRow;
