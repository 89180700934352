import { useAuth0 } from "@auth0/auth0-react";
import { Box, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../Redux/Hooks";
import HeaderProfile from "../ProfileComponent/HeaderProfile";
import ClubListitem from "./ClubListItem";

const LinkSection = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
}));

const LinkWrapper = styled("div")(() => ({
  width: "100%",
  padding: "8px",
  borderTop: "1px solid black",
}));

function HamburgerMenu() {
  const { isAuthenticated } = useAuth0();
  const { clubs } = useAppSelector((state) => state.context);

  return (
    <Box>
      <HeaderProfile />
      <LinkSection>
        {!isAuthenticated && (
          <LinkWrapper>
            <Link to="/login">Login</Link>
          </LinkWrapper>
        )}
        {!!isAuthenticated && (
          <Box sx={{ flexDirection: "row", flexGrow: 1, minWidth: "300px" }}>
            <LinkWrapper>
              <Link to="/">My Groups</Link>
            </LinkWrapper>
            {clubs.map((memberClub) => (
              <ClubListitem key={memberClub.club.id} memberClub={memberClub} />
            ))}
          </Box>
        )}
      </LinkSection>
    </Box>
  );
}

export default HamburgerMenu;
