import { proto } from "ts-igdb-client/proto/compiled";
import { GetCall } from "../Api/GetCall";
import { startFetch, fetchFailure, fetchSuccess } from "../Reducers/VoteOptions";
import { TypedThunk } from "../Redux/Hooks";
import { ItemType } from "../Types/VotingTypes";

const getItems = async (clubId: number, voteId: number, token: string) => {
  const URL = `/api/submissions/${clubId}/${voteId}/all`;
  return await GetCall(URL, token);
};

type PastVote = {
  rank: number;
  submission_id: number;
}

const getPastVote = async (voteId: number, token: string): Promise<PastVote[]> => {
  const URL = `/api/ballots/${voteId}/mine`;
  return await GetCall(URL, token);
};

export default (clubId: number, voteId: number, token: string): TypedThunk => async (dispatch) => {
  dispatch(startFetch());
  try {
    type SubmissionResponse = proto.IGame & {submission_id: number, self_submitted: boolean};
    const items = await getItems(clubId, voteId, token);
    const pastVote = await getPastVote(voteId, token);
    // TODO: share the backend models with the front end code.
    const mapped = items.map((item: SubmissionResponse): ItemType => ({
      ...item,
      ranked: !!pastVote.find((s: PastVote) => s.submission_id === item.submission_id)?.rank,
      rank: pastVote.find((s: PastVote) => s.submission_id === item.submission_id)?.rank
    })).sort((a: ItemType, b: ItemType) => (a.rank || 0) - (b.rank || 0));
    dispatch(fetchSuccess(mapped));
  } catch (exception) {
    dispatch(fetchFailure(exception as Error));
  }
};
