import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Club, MemberClub, UserContext } from "../Types/UserContextTypes";
import { SetItem, TryGetItem } from "../Utils/LocalStorageUtil";

interface UserContextState {
  loading: boolean;
  loggedIn: boolean;
  clubs: MemberClub[];
  activeClub: Club | null;
  error: any;
}

const initialState: UserContextState = {
  loading: false,
  error: null,
  loggedIn: false,
  clubs: [],
  activeClub: null,
};

export const clubSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startFetch: (state: UserContextState) => {
      state.loading = true;
      state.clubs = [];
      state.activeClub = null;
    },
    fetchSuccess: (state: UserContextState, action: PayloadAction<UserContext>) => {
      state.loading = false;
      state.loggedIn = true;
      state.clubs = action.payload.clubs;
      const activeClub = TryGetItem<UserContext>("userContext")?.activeClub;
      if (activeClub && state.clubs.find(club => club.clubId === activeClub.id)) {
        state.activeClub = activeClub;
      } else {
        state.activeClub = state.clubs[0]?.club || null;
      }
    },
    fetchFailure: (state: UserContextState, action: PayloadAction<Error>) => {
      state.loading = false;
      state.clubs = [];
      state.activeClub = null;
      state.error = action.payload;
    },
    setActiveUserContext: (state: UserContextState, action: PayloadAction<UserContext>) => {
      state.activeClub = action.payload.activeClub;
      SetItem("userContext", action.payload);
    },
  },
});

export const { startFetch, fetchSuccess, fetchFailure, setActiveUserContext } = clubSlice.actions;

export default clubSlice.reducer;
