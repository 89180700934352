import { useAuth0 } from "@auth0/auth0-react";
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { proto } from "ts-igdb-client/proto/compiled";
import { GetCall, PutCall } from "../../Api/GetCall";
import Spinner from "../Spinner/Spinner";
import TallyResults from "../TallyResults/TallyResults";
import { Vote } from "../../Types/UserContextTypes";

const VoteManager = ({ clubId, vote }: { clubId: string, vote: Vote }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [chosenGame, setChosenGame] = useState<proto.IGame | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        setAccessToken(token);
        setVoteWithGameId(vote, token);
      });
    } else {
      console.log("Not authed");
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const saveVote = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    name: string
  ) => {
    if (!accessToken) return;
    try {
      setLoading(true);
      const v = await PutCall(
        `/api/votes/${clubId}/${vote?.id}`,
        accessToken.toString(),
        JSON.stringify({
          [name]: e.target.value,
        })
      );
      await setVoteWithGameId(v);
      setLoading(false);
    } catch (ex) {
      console.error(ex);
      setLoading(false);
    }
  };

  const setVoteWithGameId = async (v: Vote, token?: string) => {
    const t = token || accessToken;
    if (v.chosen_game_id && t) {
      const game = await GetCall(`/api/games/${v.chosen_game_id}`, t);
      setChosenGame(game);
    } else if (!v.chosen_game_id) {
      setChosenGame(null);
    }
  };

  const DropDown = (name: string, options: Array<any>, selected: any) => {
    return (
      <>
        <select
          name={name}
          id={`select-${name}`}
          defaultValue={selected}
          onChange={(e) => saveVote(e, name)}
        >
          {options.map((o, i) => (
            <option value={o} key={i}>
              {o.toString()}
            </option>
          ))}
        </select>
      </>
    );
  };

  if (loading) return <Spinner height={64} width={64} />;

  if (!vote) {
    return <>There are no votes</>;
  }

  return (
    <div>
      Vote id: <b>{vote.id}</b>
      <br />
      Open:&nbsp;
      {DropDown("open", [false, true], vote.open)}
      <br />
      Submissions Open:{" "}
      {DropDown("submissionsOpen", [false, true], vote.submissions_open)}
      <br />
      Chosen game: <b>{chosenGame ? chosenGame.name : "None"}</b>
      <br />
      <Divider />
      {chosenGame && <TallyResults voteId={vote.id} />}
    </div>
  );
};

export default VoteManager;
