import { Theme as MuiTheme } from "@mui/material/styles";
import { styled } from "@mui/material";
import React from "react";
import { proto } from "ts-igdb-client/proto/compiled";
import TallyResults from "../TallyResults/TallyResults";

export interface ContainerProps {
  artworkUrl?: string;
}

export const Container = styled("div")<ContainerProps>(
  ({ theme, artworkUrl }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    overflow: "scroll",
    color: theme.palette.getContrastText(theme.palette.primary.light),
    a: {
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    ...(artworkUrl ? backgroundString(artworkUrl, theme) : {}),
  })
);

export const backgroundString = (url: string, theme: MuiTheme): object => ({
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  background: `url(${url}) no-repeat fixed center`,
  backgroundSize: "cover",
  "&::before": {
    content: "''",
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    backgroundColor: theme.palette.common.black,
    opacity: "0.4",
  },
});

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "16px",
  padding: "16px",
  backgroundColor: theme.palette.primary.light,
  zIndex: 2,
  borderRadius: "16px",
}));

export const ResultsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "16px",
  padding: "16px",
  backgroundColor: theme.palette.primary.light,
  zIndex: 2,
  borderRadius: "16px",
}));

const Content = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Artwork = styled("img")`
  border-radius: 5px;
  margin-right: 8px;
  height: 128px;
  width: 128px;
`;

const Separator = styled("hr")`
  width: 100%;
`;

export const buildArtworkUrl = (game: proto.IGame): string => {
  if (game && game.artworks && game.artworks.length) {
    const items = game.artworks;
    const url = items[0].url;
    const sized = url!
      .split("/")
      .map((p: string) => (p === "t_thumb" ? "t_1080p" : p))
      .join("/");
    return `https:${sized}`;
  }
  return "";
};

export const getName = (i: number) => {
  return (
    {
      1: "Steam Page",
      5: "GOG Page",
      11: "Xbox Page",
    }[i] || "Page"
  );
};

export const buildGameLinks = (game: proto.IGame | null): string[][] | null => {
  if (!game) return null;
  const categories = [1, 5, 11];
  if (!game.external_games) return null;
  const urls = game.external_games.map((x: proto.IExternalGame) => {
    const i = x.category || -1;
    if (categories.includes(i)) {
      if (x.category === 1) {
        return [`https://store.steampowered.com/app/${x.uid}/`, "Steam Page"];
      } else {
        return [x.url, getName(i)];
      }
    }
    return null;
  });

  return urls.filter((x) => !!x) as string[][];
};

export type VoteResultsProps = {
  game: proto.IGame | null;
  voteId: number;
};

const VoteResults = ({ game, voteId }: VoteResultsProps) => {
  if (!game) return null;

  const artworkUrl = buildArtworkUrl(game);
  const links = buildGameLinks(game);

  return (
    <Container artworkUrl={artworkUrl}>
      <Wrapper>
        <Content>
          <h2>Voting is finished</h2>
          {game && (
            <>
              <div>The winner is:</div>
              <h3>{game.name}</h3>
              <Artwork
                src={game.cover ? `https:${game.cover.url}` : undefined}
              />
              <Separator />
              <>
                {links &&
                  !!links.length &&
                  links.map(([url, name], i) => (
                    <div key={i}>
                      <a href={url} target={`_new${i}`}>
                        {name}
                      </a>
                    </div>
                  ))}
                <a href={game.url?.toString()} target="_new">
                  IGDB page
                </a>
              </>
            </>
          )}
        </Content>
      </Wrapper>
      <ResultsWrapper>
        <TallyResults voteId={voteId} />
      </ResultsWrapper>
    </Container>
  );
};

export default VoteResults;
