import buildHeaders from "./AuthUtil";

const updateMotd = async (motd: string, clubId: string, token: string) => {
  const URL = `/api/clubs/${clubId}`;
  return await fetch(URL, buildHeaders(token, "PUT", JSON.stringify({ motd })));
};

export {
  updateMotd
};
