import React, { useEffect, useState } from "react";
import { proto } from "ts-igdb-client/proto/compiled";
import { useAppSelector } from "../../Redux/Hooks";
import Spinner from "../Spinner/Spinner";

function VideoEmbed({ videoId }: { videoId: string }) {
  return (
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
    ></iframe>
  );
}

function GameInfo() {
  const { items, loading } = useAppSelector((state) => state.voteOptions);
  const [sorted, setSorted] = useState<any[]>();
  if (loading) return <Spinner height={64} width={64} />;

  useEffect(() => {
    setSorted([...(items || [])].sort((a, b) => +(a.id || 0) - +(b.id || 0)));
  }, [items]);

  return (
    <>
      {sorted?.map((item) => (
        <div key={item.id}>
          <div>{item.name}</div>
          {item.videos.map((v: proto.IGameVideo, i: number) =>
            v.video_id ? <VideoEmbed key={i} videoId={v.video_id!} /> : null
          )}
        </div>
      ))}
    </>
  );
}

export default GameInfo;
