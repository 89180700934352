import { GetCall } from "../Api/GetCall";
import { fetchFailure, fetchSuccess, startFetch } from "../Reducers/UserContextReducer";
import { TypedThunk } from "../Redux/Hooks";

const GetUserThunk = (token: string): TypedThunk => async (dispatch) => {
  dispatch(startFetch());
  try {
    const URL = "/api/users/me";
    const results = await GetCall(URL, token);
    dispatch(fetchSuccess(results));
  } catch (exception) {
    dispatch(fetchFailure(exception as Error));
  }
};

export {
  GetUserThunk
};
