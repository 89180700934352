import { gameSearch } from "../Api/Search";
import { fetchFailure, fetchSuccess, startFetch } from "../Reducers/SearchReducer";
import { TypedThunk } from "../Redux/Hooks";

const searchGamesThunk = (query: string, token: string): TypedThunk => async (dispatch) => {
  dispatch(startFetch());
  try {
    const itemList = await gameSearch(query, token);
    dispatch(fetchSuccess(itemList));
  } catch (exception) {
    dispatch(fetchFailure(exception as Error));
  }
};

export {
  searchGamesThunk
};
