import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GetCall } from "../../Api/GetCall";
import Spinner from "../Spinner/Spinner";
import { Box, Button, keyframes, styled } from "@mui/material";

export type Round = {
  game: {
    name: string;
    id: number;
  };
  votes: number;
  weight: number;
};
const animation = keyframes`
    0% {
        width: 0;
    }
`;

const Row = styled("div", {
  shouldForwardProp: (prop) => prop !== "sum" && prop !== "votes",
})<{ sum: number; votes: number }>(({ theme, sum, votes }) => ({
  color: theme.palette.primary.dark,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  position: "relative",
  "::before": {
    // eslint-disable-next-line quotes
    content: '""',
    zIndex: -1,
    position: "absolute",
    background:
      votes / sum > 0.5
        ? theme.palette.success.light
        : theme.palette.getContrastText(theme.palette.primary.dark),
    top: 0,
    bottom: 0,
    left: 0,
    width: `${(votes / sum) * 100}%`,
    transition: "width .5s cubic-bezier(.2,.6,.3,1)",
    transitionDuration: "0.5s",
    transitionTimingFunction: "cubic-bezier(.2,.6,.3,1)",
    transitionDelay: "0s",
    transitionProperty: "width",
    animation: `${animation} 0.5s ease-in-out`,
  },
  "&.eliminated": {
    color: theme.palette.error.main,
    textDecoration: "line-through",
  },
}));

const Item = styled("div")(() => ({
  padding: "8px",
}));

function TallyResults({ voteId }: { voteId: number }) {
  const [rounds, setRounds] = useState<Round[][]>([]);
  const [eliminated, setEliminated] = useState<Round | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        getRounds(token, voteId);
      });
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const getRounds = async (accessToken: string, id: number) => {
    try {
      setLoading(true);
      const url = `/api/votes/${id}/results`;
      const rounds = await GetCall(url, accessToken);
      setRounds(
        rounds.map((r: Round[]) =>
          r.sort((a, b) => +b.votes - +a.votes || b.weight - a.weight)
        )
      );
    } catch (ex) {
      console.error(ex);
      setRounds([]);
    }
    setLoading(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const prev = rounds[prevActiveStep];
      const curr = rounds[prevActiveStep + 1];
      const last = prev.find((x) => !curr.find((y) => y.game.id === x.game.id));
      setEliminated(last || null);
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      setEliminated(null);
      return prevActiveStep - 1;
    });
  };

  if (loading) {
    return <Spinner height={32} width={32} />;
  }

  if (!rounds.length) return null;

  const activeRound = rounds[activeStep];
  const sum = activeRound.reduce((acc, r) => (acc += r.votes), 0);

  return (
    <div>
      <Box sx={{ mb: 2 }}>
        <div>
          {activeRound.map((r, i) => (
            <Row key={i} votes={r.votes} sum={sum}>
              <Item>{r.game.name}</Item>
              <Item>{r.votes}</Item>
            </Row>
          ))}
          {eliminated && (
            <Row className="eliminated" votes={0} sum={sum}>
              <Item>{eliminated.game.name}</Item>
              <Item>0</Item>
            </Row>
          )}
        </div>
        <div>
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={activeStep === rounds.length - 1}
            sx={{ mt: 1, mr: 1 }}
          >
            Next
          </Button>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Previous
          </Button>
        </div>
      </Box>
    </div>
  );
}

export default TallyResults;
