import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { PostCall } from "../../Api/GetCall";
import { Member } from "../../Pages/ManageClubPage";
import SubmitButton from "../InputComponents/SubmitButton";
import InvitesTable from "./InvitesTable";
import MemberManager from "./MemberManagerRow";

export interface Props {
  handleDelete: (m: Member) => void;
  members: Member[];
}

const Header = styled.th`
  text-align: left;
`;

const InviteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
`;

const MemberManagerTable = ({ members, handleDelete }: Props) => {
  const { clubId } = useParams();
  const [inviting, setInviting] = useState<boolean>(false);
  const [invitee, setInvitee] = useState<string>("");
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setAccessToken(token);
    });
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const inviteUser = async () => {
    if (!accessToken) {
      return;
    }

    setInviting(true);
    try {
      await PostCall(
        `/api/invites/${clubId}`,
        accessToken,
        JSON.stringify({ email: invitee })
      );
    } catch (ex) {
      console.error(ex);
    }
    setInviting(false);
    setInvitee("");
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <Header>Name</Header>
            <Header>Email</Header>
            <Header>Role</Header>
            <Header>Kick</Header>
          </tr>
        </thead>
        <tbody>
          {members.map((m) => (
            <MemberManager
              key={m.id}
              handleDelete={() => handleDelete(m)}
              name={m.user.name}
              email={m.user.email}
              role={m.role}
            />
          ))}
        </tbody>
      </table>
      <h3>Pending Invites</h3>
      {!inviting && <InvitesTable clubId={clubId} />}
      <InviteWrapper>
        <h3>Invite someone</h3>
        <label>
          Gmail:&nbsp;
          <input
            type="text"
            onChange={(e) => setInvitee(e.target.value)}
            value={invitee}
          />
        </label>
        <br />
        <SubmitButton disabled={inviting} text="Invite" onClick={inviteUser} />
      </InviteWrapper>
    </>
  );
};

export default MemberManagerTable;
