import buildHeaders from "./AuthUtil";

const GetCall = async (url: string, accessToken: string) => {
  const response = await fetch(url, buildHeaders(accessToken));
  if (!response.ok) {
    throw new Error(`Error performing GET ${url}`);
  }

  return await response.json();
};

const DeleteCall = async (url: string, accessToken: string) => {
  const response = await fetch(url, buildHeaders(accessToken, "DELETE"));
  if (!response.ok) {
    throw new Error(`Error performing DELETE ${url}`);
  }

  return response;
};

const PostCall = async (url: string, accessToken: string, body?: string) => {
  const response = await fetch(url, buildHeaders(accessToken, "POST", body));
  if (!response.ok) {
    throw new Error(`Error performing POST ${url}`);
  }

  return response.json();
};

const PutCall = async (url: string, accessToken: string, body: string) => {
  const response = await fetch(url, buildHeaders(accessToken, "PUT", body));
  if (!response.ok) {
    throw new Error(`Error performing PUT ${url}`);
  }

  return response.json();
};

export {
  GetCall,
  DeleteCall,
  PutCall,
  PostCall
};
