import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { unstageItem, stageItem, updateOrder } from "../Reducers/VoteOptions";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks";
import getVoteOptions from "../Thunks/VoteOptionsThunks";
import { submitVotes } from "../Thunks/VotingThunks";
import { ItemType, Ranked } from "../Types/VotingTypes";
import List from "../Components/VoteList/List";
import SubmitButton from "../Components/InputComponents/SubmitButton";
import GamePill from "../Components/GamePill/GamePill";
import { useParams } from "react-router-dom";
import Spinner from "../Components/Spinner/Spinner";
import { Vote as VoteModel } from "../Types/UserContextTypes";
import { GetCall } from "../Api/GetCall";
import VoteResults from "../Components/VoteResults/VoteResults";
import { proto } from "ts-igdb-client/proto/compiled";
import { useAuth0 } from "@auth0/auth0-react";
import GameInfo from "../Components/GameInfo/GameInfo";

const PillContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  cursor: pointer;
`;

const VoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  width: 300px;
  margin-top: 8px;
  display: flex;
  justify-content: end;
`;

const ErrorText = styled.h2`
  color: red;
`;

function Vote() {
  const [loadingVote, setLoadingVote] = useState<boolean>(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { items, loading } = useAppSelector((state) => state.voteOptions);
  const { submitting, submitted } = useAppSelector((state) => state.voting);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { clubId } = useParams();
  const [vote, setVote] = useState<VoteModel | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [game, setGame] = useState<proto.IGame | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        getVoteAndOptions(token);
        setAccessToken(token);
      });
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const getVoteAndOptions = async (accessToken: string) => {
    try {
      setLoadingVote(true);
      const URL = `/api/votes/${clubId}/latest`;
      const v = await GetCall(URL, accessToken.toString());
      setVote(v);
      setLoadingVote(false);
      dispatch(getVoteOptions(+clubId!, +v.id, accessToken));
    } catch (ex) {
      console.error(ex);
      setVote(null);
      setLoadingVote(false);
    }
  };

  const rankedItems = useMemo<ItemType[]>(
    () => items?.filter(({ ranked }) => ranked) || [],
    [items]
  );

  const unRankedItems = useMemo<ItemType[]>(
    () => items?.filter(({ ranked }) => !ranked) || [],
    [items]
  );

  const reorder = (updatedItems: ItemType[]) => {
    setError(null);
    dispatch(updateOrder(updatedItems));
  };

  const addItem = (item: ItemType) => {
    setError(null);
    dispatch(stageItem(item));
  };

  const unstage = (item: ItemType) => {
    setError(null);
    if (rankedItems.length) {
      dispatch(unstageItem(item));
    }
  };

  useEffect(() => {
    if (vote && vote.chosen_game_id && accessToken) {
      getGameFromId(vote.chosen_game_id, accessToken);
    }
  }, [accessToken, clubId, vote]);

  const getGameFromId = async (gameId: number, accessToken: string) => {
    const game = await GetCall(`/api/games/${gameId}`, accessToken);
    setGame(game);
  };

  const onSubmit = () => {
    if (!vote || !accessToken) return;
    const ranked = rankedItems.map(
      (r, i): Ranked => ({
        submission_id: r.submission_id,
        self_submitted: r.self_submitted,
        rank: i + 1,
      })
    );

    if (ranked[0].self_submitted) {
      setError("Don't vote for your own game as #1, asshole");
    } else {
      dispatch(submitVotes(ranked, +vote.id, accessToken));
    }
  };

  if (loading || loadingVote || !accessToken) {
    return <Spinner width={50} height={50} />;
  }

  if (!vote?.open) {
    if (vote?.chosen_game_id) {
      return <VoteResults game={game} voteId={vote.id} />;
    } else {
      return <h2>Voting has not yet opened for this round.</h2>;
    }
  }

  if (items === null) return null;

  if (!items.length) {
    return <h2>No games have been submitted yet.</h2>;
  }

  return (
    <PageContainer>
      <VoteContainer>
        <h2>Rank games in any order</h2>
        {!!submitted && <h3>You voted!</h3>}
        {!!error && <ErrorText>{error}</ErrorText>}
        <List
          count={items.length}
          items={rankedItems}
          loading={loading}
          reorder={reorder}
          unstage={unstage}
        />
        {!!unRankedItems.length && <h2>Click on a game to rank it</h2>}
        <PillContainer>
          {items &&
            unRankedItems.map((item, i) => (
              <GamePill
                onClick={() => addItem(item)}
                text={item.name as string}
                iconUrl={item.cover ? `https:${item.cover.url}` : undefined}
                key={i}
              ></GamePill>
            ))}
        </PillContainer>
        {rankedItems && !!rankedItems.length && (
          <ButtonContainer>
            <SubmitButton
              text="Submit"
              loading={submitting}
              onClick={onSubmit}
            />
          </ButtonContainer>
        )}
      </VoteContainer>
      <OptionsContainer>
        <GameInfo />
      </OptionsContainer>
    </PageContainer>
  );
}

export default Vote;
