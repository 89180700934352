import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetCall } from "../Api/GetCall";
import ReactMarkdown from "react-markdown";
import Spinner from "../Components/Spinner/Spinner";
import { Club } from "../Types/UserContextTypes";
import { useAuth0 } from "@auth0/auth0-react";

const ClubPage = () => {
  const { clubId } = useParams();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const [club, setClub] = useState<Club | null>(null);

  const getClubByID = async (clubId: number, token: string) => {
    try {
      const res = await GetCall(`/api/clubs/${clubId}`, token);
      setClub(res);
    } catch (ex) {
      console.error(ex);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!clubId) return;
    getAccessTokenSilently().then((token) => {
      getClubByID(+clubId, token);
    });
  }, [user, isAuthenticated, getAccessTokenSilently]);

  if (loading) {
    return <Spinner width={50} height={50} />;
  }

  return (
    <div>
      {club && <h1>{club.name}</h1>}
      <hr />
      {club && club.motd && <ReactMarkdown>{club?.motd}</ReactMarkdown>}
    </div>
  );
};

export default ClubPage;
