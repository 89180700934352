import React, { useState } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import styled from "styled-components";
import store from "./Redux/Store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import useMediaQuery from "@mui/material/useMediaQuery";

import Vote from "./Pages/Vote";
import Submit from "./Pages/Submit";
import ClubPage from "./Pages/ClubPage";
import ClubSelection from "./Pages/ClubSelection";
import LoginPage from "./Pages/LoginPage";
import AuthWrapper from "./Components/AuthWrapper/AuthWrapper";
import ManageClub from "./Pages/ManageClubPage";
import SuggestionsPage from "./Pages/Suggestions";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  AppBar,
  Box,
  FormControlLabel,
  FormGroup,
  IconButton,
  SwipeableDrawer,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import HamburgerMenu from "./Components/HamburgerMenu/HamburgerMenu";

const Main = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

const PageContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 64px;
  right: 0;
  left: 0;
  bottom: 0;
`;

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

function TopBar({
  isDarkTheme,
  setDarkTheme,
}: {
  isDarkTheme: boolean;
  setDarkTheme: (d: boolean) => void;
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <HamburgerMenu />
      </SwipeableDrawer>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Video Game Scholars Club
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDarkTheme}
                    onChange={() => setDarkTheme(!isDarkTheme)}
                  />
                }
                label="Dark Theme"
              />
            </FormGroup>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [isDarkTheme, setDarkTheme] = useState(prefersDarkMode);

  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
          <CssBaseline enableColorScheme />
          <PageContainer>
            <TopBar isDarkTheme={isDarkTheme} setDarkTheme={setDarkTheme} />
            <AuthWrapper>
              <Main>
                <Routes>
                  <Route path="/" element={<ClubSelection />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/club/:clubId" element={<ClubPage />} />
                  <Route path="/club/:clubId/manage" element={<ManageClub />} />
                  <Route path="/club/:clubId/vote" element={<Vote />} />
                  <Route path="/club/:clubId/submit" element={<Submit />} />
                  <Route path="/suggestions" element={<SuggestionsPage />} />
                </Routes>
              </Main>
            </AuthWrapper>
          </PageContainer>
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

function Wrapper() {
  return (
    <Provider store={store}>
      <Auth0Provider
        domain="dev-vizjnu3po6ow4fy4.us.auth0.com"
        clientId="RW7mNaUkiHN6DEOGR8K6eozH9Aop5Jb3"
        redirectUri={window.location.origin}
        audience="https://dev-vizjnu3po6ow4fy4.us.auth0.com/api/v2/"
        scope="read:current_user update:current_user_metadata openid email profile"
      >
        <App />
      </Auth0Provider>
    </Provider>
  );
}

export default withAITracking(reactPlugin, Wrapper);
