import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DeleteCall, GetCall } from "../../Api/GetCall";
import DeleteIcon from "../DeleteIcon";

const Header = styled.th`
  text-align: left;
`;

const InvitesTable = ({ clubId }: { clubId?: string }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [invites, setInvites] = useState<any[]>([]);
  if (!clubId) return null;

  const getInvites = async (accessToken: string) => {
    try {
      setLoading(true);
      const invites = await GetCall(`/api/invites/${clubId}`, accessToken);
      setInvites(invites);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setAccessToken(token);
      getInvites(token);
    });
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const handleDelete = async (id: number) => {
    if (!accessToken) return;
    try {
      setLoading(true);
      await DeleteCall(`/api/invites/${clubId}/${id}`, accessToken);
      await getInvites(accessToken);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  if (loading) return null;

  if (!invites.length) return <>No pending invites</>;

  return (
    <div>
      <table>
        <thead>
          <tr>
            <Header>Email</Header>
            <Header>Delete</Header>
          </tr>
        </thead>
        <tbody>
          {invites.map((i) => (
            <tr key={i.id}>
              <td>{i.email}</td>
              <td>
                <DeleteIcon handleDelete={() => handleDelete(i.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvitesTable;
