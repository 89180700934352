import React, { useEffect, useState } from "react";
import { Vote } from "../../Types/UserContextTypes";
import { GetCall } from "../../Api/GetCall";
import Spinner from "../Spinner/Spinner";

// Add a vote parameter to the properties of this component so we can pass it in directly
export interface Props {
  vote: Vote,
  accessToken: string
}

interface Turnout {
  // key is a string (email), and value is a number
  [key: string]: boolean
}

function VoteTurnout(props: Props) {
  const { vote, accessToken } = props;
  // The above is shorthand for the following:
  // const vote = props.vote;
  // const clubId = props.clubId;
  const [loading, setLoading] = useState<boolean>(false);
  const [turnout, setTurnout] = useState<Turnout | null>(null);

  const getTurnout = async () => {
    // Get the vote turnout here
    setLoading(true);
    try {
      const turnout = await GetCall(`/api/votes/${vote.id}/turnout`, accessToken) as Turnout;
      setTurnout(turnout);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Get the vote turnout here
    getTurnout();
  }, [vote]);

  if (loading) return <Spinner height={32} width={32} />;

  if (!turnout) {
    return null;
  }

  // Render the Turnout to show users by email and whether or not they cast ballots this round
  return (
    <>
      <h3>Vote Turnout</h3>
      <table>
        <thead>
          <tr>
            <th>
                Member
            </th>
            <th>
                Vote Cast
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(turnout).map(([email, voted]) => (
            <tr key={email}>
              <td>{email}</td>
              <td>{`${voted}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default VoteTurnout;
